import { REQUIRED_FIELD } from '@/utils/validationMessagesUtils'

export default {
  ascertainedMethodIdErrors () {
    const errors = []
    if (!this.$v.item.ascertainedMethodId.$dirty) return errors
    !this.$v.item.ascertainedMethodId.required && errors.push('Modalidade é obrigatório')
    return errors
  },

  codErrors () {
    const errors = []
    if (!this.$v.item.cod.$dirty) return errors
    !this.$v.item.cod.required && errors.push('O COD é obrigatório.')
    return errors
  },

  dtStartErrors () {
    const errors = []
    if (this.$v.item.dtStart.$dirty) {
      !this.$v.item.dtStart.required && errors.push('Data de início é obrigatório.')
    }
      // } else {
    //   let dtStart = moment(this.$v.item.dtStart.$model).format('DD/MM/YYYY H:mm:ss')
    //   let dtEnd = moment(this.$v.item.dtEnd.$model).format('DD/MM/YYYY H:mm:ss')

    //   if (dtStart === dtEnd && dtStart !== 'Invalid date') {
    //     errors.push('AVISO! Data de início e final são iguais.')
    //   }
    // }
    return errors
  },

  dtEndErrors () {
    const errors = []
    if (this.$v.item.dtEnd.$dirty) {
      !this.$v.item.dtEnd.required && errors.push('Data de início é obrigatório.')
      !this.$v.item.dtEnd.invalidDate && errors.push('Data não pode ser menor ou igual a data de inicio!')
    }
    // } else {
    //   let dtStart = moment(this.$v.item.dtStart.$model).format('DD/MM/YYYY H:mm:ss')
    //   let dtEnd = moment(this.$v.item.dtEnd.$model).format('DD/MM/YYYY H:mm:ss')

    //   if (dtStart === dtEnd && dtStart !== 'Invalid date') {
    //     errors.push('AVISO! Data de início e final são iguais.')
    //   }
    // }
    return errors
  },

  dtDrawErrors () {
    const errors = []
    if (!this.$v.item.dtDraw.$dirty) return errors
    !this.$v.item.dtDraw.required && errors.push('Data de sorteio é obrigatório.')
    !this.$v.item.dtDraw.invalidDate && errors.push('Data não pode ser menor que a data de fim do concurso!')
    return errors
  },

  priceErrors () {
    const errors = []
    if (!this.$v.item.config.price.$dirty) return errors
    !this.$v.item.config.price.required && errors.push(REQUIRED_FIELD)
    !this.$v.item.config.price.minValue && errors.push('Valor mínimo é R$ 0.01.')
    return errors
  },

  sizeLotError () {
    const errors = []
    if (!this.$v.item.config.sizeLot.$dirty) return errors
    !this.$v.item.config.sizeLot.required && errors.push('Tamanho do lote de cupons é obrigatório.')
    !this.$v.item.config.sizeLot.minValue && errors.push('O valor tem que ser maior que 0.')
    return errors
  },

  // timerError () {
  //   const errors = []
  //   if (!this.$v.item.config.timer.$dirty) return errors
  //   !this.$v.item.config.timer.required && errors.push('Obrigatório o timer em minutos')
  //   return errors
  // },
  
  // maxBallsError() {
  //   const errors = []
  //   if (!this.$v.item.config.accumulation.maxBalls.$dirty) return errors
  //   !this.$v.item.config.accumulation.maxBalls.required && errors.push('Campo Obrigatório')
  //   return errors
  // },

  // percentageError () {
  //   const errors = []
  //   if (!this.$v.item.config.accumulation.percentage.$dirty) return errors
  //   !this.$v.item.config.accumulation.percentage.required && errors.push('Campo Obrigatório')
  //   return errors
  // },
  
  maxNumberError () {
    const errors = []
    if (!this.$v.item.config.cardConfig.maxNumber.$dirty) return errors
    !this.$v.item.config.cardConfig.maxNumber.required && errors.push('Campo Obrigatório')
    return errors
  },

  prevSalesError () {
    const errors = []
    if (!this.$v.item.config.cardConfig.expectedSales.$dirty) return errors
    !this.$v.item.config.cardConfig.expectedSales.required && errors.push('Campo Obrigatório')
    !this.$v.item.config.cardConfig.expectedSales.minValue && errors.push('Campo Obrigatório')
    return errors
  },

  payoutError () {
    const errors = []
    if (!this.$v.item.config.cardConfig.payoutPercentage.$dirty) return errors
    !this.$v.item.config.cardConfig.payoutPercentage.required && errors.push('Campo Obrigatório')
    !this.$v.item.config.cardConfig.payoutPercentage.minValue && errors.push('Campo Obrigatório')
    return errors
  }
}
