<template>
  <v-dialog persistent  v-model="activeModal" max-width="1230px"  class="modal-cadastro" :key="keyModal">
    <template v-slot:activator="{}">
      <v-btn
        tile
        :color="variables.colorPrimary"
        dark class="mb-2 modal-cadastro-btn-cadastrar"
        @click="openModal"
        :loading="loading"
        v-if="permiteAcao($route, 'add')">Novo cadastro</v-btn>
    </template>
    <v-card>
      <v-toolbar flat height="50px" class="modal-cadastro-toolbar">
          <div class="abas" width="100%">
            <v-tabs
              v-model="tab"
              background-color="#FFF"
              height="30px">
              <v-tab href="#tab-1" class="abas-item">
                Concurso
              </v-tab>

              <v-tab href="#tab-2" class="abas-item" :disabled="$v.item.$invalid">
                Prêmios
              </v-tab>
            </v-tabs>
          </div>
          <v-spacer></v-spacer>
          <v-icon @click="closeModal" class="modal-cadastro-close">close</v-icon>
      </v-toolbar>
      
      <v-tabs-items v-model="tab" touchless>
        <v-tab-item
          :key="1"
          :value="'tab-1'">
          <v-form>
            <v-card-title class="modal-cadastro-titulo">
              {{ isEdit ? 'Editar concurso' : 'Cadastrar concurso' }}
            </v-card-title>

            <v-card-text class="modal-cadastro-form">
              <v-row>
                <v-col cols="12" class="modal-cadastro-form-col-imput" :sm="visibleQtdCupons || isBingo ? '4': '6'">
                  <v-select :error-messages="ascertainedMethodIdErrors" :items="listaItemSelectMethods" label="Modalidades" v-model="item.ascertainedMethodId" @input="$v.item.ascertainedMethodId.$touch()" @blur="$v.item.ascertainedMethodId.$touch()"></v-select>
                </v-col>
                <v-col cols="12" class="modal-cadastro-form-col-imput" :sm="visibleQtdCupons || isBingo ? '4': '6'">
                  <v-text-field :error-messages="codErrors" v-model="item.cod" label="COD" @input="$v.item.cod.$touch()" @blur="$v.item.cod.$touch()" />
                </v-col>

                <v-col cols="12" class="modal-cadastro-form-col-imput" sm="4" v-if="visibleQtdCupons">
                  <!-- <v-text-field type="number" :error-messages="sizeLotError" v-model="item.config.sizeLot" label="Quantidade de cupons" @input="$v.item.config.sizeLot.$touch()" @blur="$v.item.config.sizeLot.$touch()" /> -->
                  <vmoney type="number" :error="sizeLotError" v-model="item.config.sizeLot" label="Quantidade de cupons" @input="$v.item.config.sizeLot.$touch()" @blur="$v.item.config.sizeLot.$touch()" :options="config" />
                </v-col>

                <!-- <v-col cols="12" class="modal-cadastro-form-col-imput" sm="4" v-if="isBingo">
                  <v-text-field type="number" v-model="item.config.timer" label="Tempo em minutos" />
                </v-col> -->

                <v-col cols="12" class="modal-cadastro-form-col-imput" sm="2" v-if="isBingo">
                  <v-currency-field
                    v-model="item.config.cardConfig.expectedSales"
                    label="Previsão de vendas"
                    prefix="R$"
                    :error-messages="prevSalesError"
                    @input="$v.item.config.cardConfig.expectedSales.$touch()"
                    @blur="$v.item.config.cardConfig.expectedSales.$touch()"
                  />
                </v-col>

                <v-col cols="12" class="modal-cadastro-form-col-imput" sm="2" v-if="isBingo">
                  <v-currency-field
                    v-model="item.config.cardConfig.payoutPercentage"
                    label="Payout"
                    prefix="%"
                    :error-messages="payoutError"
                    @input="$v.item.config.cardConfig.payoutPercentage.$touch()"
                    @blur="$v.item.config.cardConfig.payoutPercentage.$touch()"
                  />
                </v-col>

                <v-col cols="12" class="modal-cadastro-form-col-imput" sm="2" v-if="isBingo">
                  <v-autocomplete
                    v-model="item.config.cardConfig.numCols"
                    :items="[3, 4, 5]"
                    type="tel"
                    label="Qtd. Colunas"
                    disabled
                  />
                </v-col>

                <v-col cols="12" class="modal-cadastro-form-col-imput" sm="2" v-if="isBingo">
                  <v-autocomplete
                    v-model="item.config.cardConfig.numLines"
                    :items="[3, 4, 5]"
                    type="tel"
                    label="Qtd. Linhas"
                    disabled
                  />
                </v-col>

                <v-col cols="12" class="modal-cadastro-form-col-imput" sm="2" v-if="isBingo">
                  <v-autocomplete
                    v-model="item.config.cardConfig.maxNumber"
                    label="Max. Bolas"
                    :items="[90]"
                    :error="maxNumberError"
                    @input="$v.item.config.cardConfig.maxNumber.$touch()"
                    @blur="$v.item.config.cardConfig.maxNumber.$touch()"
                    disabled
                  />
                </v-col>
<!-- 
                <v-col cols="12" class="modal-cadastro-form-col-imput" sm="2" v-if="isBingo">
                  <vmoney 
                    type="number"
                    v-model="item.config.cardConfig.maxNumber"
                    label="Max. Bolas"
                    :options="config" 
                    :error="maxNumberError"
                    @input="$v.item.config.cardConfig.maxNumber.$touch()"
                    @blur="$v.item.config.cardConfig.maxNumber.$touch()"/>
                </v-col> -->

                <v-col cols="12" class="modal-cadastro-form-col-imput" sm="3" v-if="isBingo">
                  <vmoney 
                    type="number"
                    v-model="item.config.accumulation.maxBalls"
                    label="Max. Bola Acumulado"
                    :options="config"
                  />
                </v-col>
                
                <v-col cols="12" class="modal-cadastro-form-col-imput" sm="3" v-if="isBingo">
                  <vmoney 
                    type="number"
                    v-model="item.config.accumulation.percentage"
                    label="% Acumulado"
                    :options="configPorcentage"
                  />
                </v-col>

                <v-col cols="12" class="modal-cadastro-form-col-imput" sm="4">
                  <dateTimerPicker
                    label="Data de início do concurso"
                    v-model="item.dtStart"
                    :datePickerProps="{locale: $store.getters.Locale}"
                    :timePickerProps="{format: '24hr'}"
                    date-format="dd/MM/yyyy"
                    time-format="HH:mm"
                    :datetime="datetime"
                    :textFieldProps="{errorMessages: dtStartErrors}"
                    :key='componentKey1'
                    clearText="Limpar">
                  </dateTimerPicker>
                </v-col>

                <v-col cols="12" class="modal-cadastro-form-col-imput" sm="4">
                  <dateTimerPicker
                    label="Data do fim do concurso"
                    v-model="item.dtEnd"
                    :datePickerProps="{locale: $store.getters.Locale, min: dataMinima(item.dtStart)}"
                    :timePickerProps="{format: '24hr', min: horaMinimaStart}"
                    date-format="dd/MM/yyyy"
                    time-format="HH:mm"
                    :datetime="datetime"
                    :textFieldProps="{errorMessages: dtEndErrors}"
                    :key='componentKey2'
                    clearText="Limpar"
                    @valorDate="compararDataEnd">
                  </dateTimerPicker>
                </v-col>

                <v-col cols="12" class="modal-cadastro-form-col-imput" sm="4">
                  <dateTimerPicker
                    label="Data de sorteio do concurso"
                    v-model="item.dtDraw"
                    :datePickerProps="{locale: $store.getters.Locale, min: dataMinima(item.dtEnd)}"
                    :timePickerProps="{format: '24hr', min: horaMinimaDraw}"
                    date-format="dd/MM/yyyy"
                    time-format="HH:mm"
                    :datetime="datetime"
                    :textFieldProps="{errorMessages: dtDrawErrors}"
                    :key='componentKey3'
                    clearText="Limpar"
                    @valorDate="compararDataDraw">
                  </dateTimerPicker>
                </v-col>

                <v-col cols="12" class="modal-cadastro-form-col-imput" sm="2">
                  <v-currency-field v-model="item.config.price" label="Preço do Cupom" prefix="R$" :error-messages="priceErrors"/>
                </v-col>
                
                <!-- upload de imagem do concurso -->
                <v-col cols="12" class="modal-cadastro-form-col-imput" sm="2">
                  <v-file-input
                    accept="image/*"
                    label="Concurso"
                    prepend-icon="mdi-camera"
                    v-model="image"
                  ></v-file-input>
                </v-col> 

                <!-- upload de imagem do cupom -->
                <v-col cols="12" class="modal-cadastro-form-col-imput" sm="3">
                  <v-file-input
                    accept="image/*"
                    label="Frente do Cupom"
                    prepend-icon="mdi-camera"
                    v-model="imageCupom"
                  ></v-file-input>
                </v-col> 

                <!-- upload de imagem do verso do cupom -->
                <v-col cols="12" class="modal-cadastro-form-col-imput" sm="3">
                  <v-file-input
                    accept="image/*"
                    label="Verso do Cupom"
                    prepend-icon="mdi-camera"
                    v-model="imageCupomVerso"
                  ></v-file-input>
                </v-col> 

                <!-- upload do regulamento do concurso -->
                <v-col cols="12" class="modal-cadastro-form-col-imput" sm="2">
                  <v-file-input
                    label="Regulamento"
                    prepend-icon="mdi-paperclip"
                    v-model="regulationSweepstakes"
                    accept="application/pdf"
                  ></v-file-input>
                </v-col> 

                <v-col cols="12">
                  <v-row>
                    <v-col v-show="item.image" sm="3">
                      <div class="wrapper-image">
                        <v-img :src="item.image" aspect-ratio="2.5"></v-img>
                        <span class="faixa">Concurso</span>
                      </div>
                    </v-col>

                    <v-col v-show="item.imageCupom" sm="3">
                      <div class="wrapper-image">
                        <v-img :src="item.imageCupom" aspect-ratio="2.5"></v-img>
                        <span class="faixa">Cupom Frente</span>
                      </div>
                    </v-col>

                    <v-col v-show="item.imageCupomVerso" sm="3">
                      <div class="wrapper-image">
                        <v-img :src="item.imageCupomVerso" aspect-ratio="2.5"></v-img>
                        <span class="faixa">Cupom Verso</span>
                      </div>

                    </v-col>
                    <v-col v-show="item.regulationSweepstakes" sm="3">
                      <div class="wrapper-image">
                        <a :href="item.regulationSweepstakes">
                          <v-img src="@/assets/img/placeholder-pdf.jpg" aspect-ratio="2.5"></v-img>
                          <span class="faixa">Regulamento</span>
                        </a>
                      </div>
                    </v-col>

                    <v-col cols="12" class="pt-0 pb-0" v-if="isBingo">
                      <v-checkbox
                        label="Número bônus"
                        v-model="item.config.cardConfig.centralNumberBonus"
                        class="mt-0 mb-0"
                      ></v-checkbox>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-card-text>

            <v-card-actions class="modal-cadastro-footer">
              <v-btn :color="variables.colorPrimary" @click="closeModal" class="br-btn br-btn-cancelar">Cancelar</v-btn>
              <v-btn type="submit" :color="variables.colorPrimary" @click.prevent="cadastrarConcurso" class="br-btn" :loading="loading" v-if="isEdit">{{ isEdit ? 'Salvar' : 'Cadastras' }}</v-btn>
              <v-btn type="submit" :color="variables.colorPrimary" @click.prevent="proximo" class="br-btn" :loading="loading" v-else>{{ isEdit ? 'Salvar' : 'Próximo' }}</v-btn>
            </v-card-actions>
          </v-form>
        </v-tab-item>

        <v-tab-item
          :key="2"
          :value="'tab-2'">
          <prizes :sweepstakes="item" :valueKey="valueKey" :loadingCadastro="loadingCadastro" @cadastrar-concurso="cadastrarConcurso" @voltar="tab = 'tab-1'" />
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { validationMixin } from 'vuelidate'
import { required, minValue, requiredIf } from 'vuelidate/lib/validators'
import Events from '@/core/service/events'
import variables from '@/assets/styles/helpers/_variables.scss'
import moment from 'moment'
import { cloneDeep, orderBy } from 'lodash'
// import VCurrencyField from '@/views/components/vCurrencyField'
// import uploadImage from './uploadImage'
import { errorSnackbar, successSnackbar } from '@/core/service/utils'
import vmoney from '@/views/components/vMoney'

import validacao from './validacao'

const paramsInvalidDateEnd = (dtStart) => value => {
  return !moment(value, 'DD/MM/YYYY HH:mm').isSameOrBefore(dtStart, 'DD/MM/YYYY HH:mm') ? !!1 : !!0
}
const paramsInvalidDateDraw = (dtEnd) => value => {
  const validated = moment(value, 'DD/MM/YYYY').isBefore(dtEnd, 'DD/MM/YYYY')
  return !validated ? !!1 : !!0
}

const namespaceStore = 'sweepstakes'

export default {
  name: 'ModalCadastroConcurso',
  mixins: [validationMixin],
  components: {
    prizes: () => import('./prizes/index'),
    dateTimerPicker: () => import('@/views/components/dateTimePicker'),
    vmoney
  },
  data: () => ({
    activeModal: false,
    loading: false,
    loadingCadastro: false,
    menu: false,
    datetime: new Date(),
    image: [],
    imageCupom: [],
    imageCupomVerso: [],
    regulationSweepstakes: [],
    componentKey1: 0,
    componentKey2: 1000,
    componentKey3: 100000,
    tab: 'tab-1',
    valueKey: 0,
    keyModal: 0,
    horaMinimaStart: '00:00',
    horaMinimaDraw: '00:00',
    menuDatePicker: false,
    config: {
      locale: 'pt-BR',
      prefix: '',
      suffix: '',
      length: 100,
      precision: 0
    },
    configPorcentage: {
      locale: 'pt-BR',
      prefix: '',
      suffix: '%',
      length: 100,
      precision: 0
    },
    cloneSweepstakes: {}
  }),
  
  watch: {
    'image' (val) {
      this.addImage(val)
    }, 

    'imageCupom' (val) {
      this.addCupons(val)
    },

    'imageCupomVerso' (val) {
      this.addCuponVerso(val)
    },

    'regulationSweepstakes' (val) {
      this.addRegulationSweepstakes(val)
    }
  },

  computed: {
    ...mapGetters('roles', ['permiteAcao']),
    ...mapGetters('prizes', ['listaItens']),
    ...mapGetters(namespaceStore, ['item', 'listaItemSelectMethods']),
    ...validacao,
    variables: () => variables,
    
    isBingo () {
      return this.item.ascertainedMethodId === 'BINGO'
    },

    isEdit () {
      return this.item.id ? !!1 : !!0
    },

    computedDateFormatted () {
      return this.formatDate(this.date)
    },

    visibleQtdCupons () {
      switch (this.item.ascertainedMethodId) {
        case 'RASPADINHA':
        case 'BAUF':
        case 'BAU':
          return true
        default:
          return false
      }
    }
  },
  mounted () {

    const self = this
    this.getMethos()
    Events.$on('cadastro::openModalCadastro', () => {
      this.activeModal = true

      if (self.item.id) {
        self.cloneSweepstakes = cloneDeep(self.item)
      }

    })
  },
  methods: {
    ...mapActions(namespaceStore, ['cadastrarItem', 'limparItem', 'getItens', 'editarItem', 'getMethos']),
    ...mapActions('prizes', {
      obterPremios: 'obterItens',
      limparItemPremios: 'limparItens'
    }),
    ...mapActions('prizeTypes', {
      getItensPrizeTypes: 'getItens'
    }),

    openModal () {
      this.activeModal = true
    },

    forceRerender() {
      this.componentKey1 += 1  
      this.componentKey2 += 1  
      this.componentKey3 += 1
      this.valueKey += 1
      this.keyModal += 1
    },

    closeModal () {
      this.forceRerender()
      this.image = null
      this.imageCupom = null
      this.imageCupomVerso = null
      this.regulationSweepstakes = null
      this.activeModal = false
      this.limparItem()
      this.limparItemPremios()
      this.tab = 'tab-1'
      this.$v.item.$reset()
      Events.$emit('modalCadastroSweepstakes::closeModal')
    },

    dataMinima (date = '01/01/1900') {
      return moment(date, 'DD/MM/YYYY').format('YYYY-MM-DD')
    },

    compararDataEnd(payload) {
      if (moment(this.item.dtStart).isSame(moment(payload, 'YYYY-MM-DD'), 'day')) {
        this.horaMinimaStart = moment(this.item.dtStart, 'DD/MM/YYYY HH:mm').format('HH:mm')
        return
      } 

      this.horaMinimaStart = ''
    },

    compararDataDraw(payload) {
       if (moment(this.item.dtEnd).isSame(moment(payload, 'YYYY-MM-DD'), 'day')) {
        this.horaMinimaDraw = moment(this.item.dtEnd, 'DD/MM/YYYY HH:mm').format('HH:mm')
        return
      }

      this.horaMinimaDraw = ''
    },

    formatDate (date) {
      moment().locale(this.$store.getters.Locale)
      if (!date) return null
      return moment(date).format('DD/MM/YYYY')
    },

    addImage (e) {
      if (!e) {
        this.item.image = ''
        return
      }
      if (!e.type.match('image.*')) {
        this.item.image = ''
        return
      }
      // const img = new Image(),
      const reader = new FileReader();
      reader.onload = (e) => this.item.image = e.target.result;
      reader.readAsDataURL(e);
    },

    addCupons (e) {
      if (!e) {
        this.item.imageCupom = ''
        return
      }
      if (!e.type.match('image.*')) {
        this.item.imageCupom = ''
        return
      }
      // const img = new Image(),
      const reader = new FileReader();
      reader.onload = (e) => this.item.imageCupom = e.target.result;
      reader.readAsDataURL(e);
    },

    addCuponVerso (e) {
      if (!e) {
        this.item.imageCupomVerso = ''
        return
      }
      if (!e.type.match('image.*')) {
        this.item.imageCupomVerso = ''
        return
      }
      // const img = new Image(),
      const reader = new FileReader();
      reader.onload = (e) => this.item.imageCupomVerso = e.target.result;
      reader.readAsDataURL(e);
    },

    addRegulationSweepstakes (e) {
      if (!e) {
        this.item.regulationSweepstakes = ''
        return
      }
      if (!e.type.match('application/pdf')) {
        this.item.regulationSweepstakes = ''
        return
      }
      // const img = new Image(),
      const reader = new FileReader();
      reader.onload = (e) => this.item.regulationSweepstakes = e.target.result;
      reader.readAsDataURL(e);
    },

    proximo () {
      if (this.$v.item.$invalid) { 
        this.$v.item.$touch()
        return false 
      }

      this.tab = 'tab-2'
    },

    cadastrarConcurso () {
      this.loading = true
      this.loadingCadastro = true

      this.item.config = {
        price: this.item.config ? this.item.config.price : this.item.config,
        cardConfig: this.item.config ? this.item.config.cardConfig : this.item.config,
        accumulation: this.item.config ? this.item.config.accumulation : this.item.config,
        sizeLot: this.item.config ? this.item.config.sizeLot : this.item.config,
        timer: this.config.timer
      }

      const dados = {
        ascertainedMethodId: this.item.ascertainedMethodId,
        cod: this.item.cod,
        dtStart: moment(this.item.dtStart, 'DD/MM/YYYY HH:mm').format('YYYY-MM-DD HH:mm'),
        dtEnd: moment(this.item.dtEnd, 'DD/MM/YYYY HH:mm').format('YYYY-MM-DD HH:mm'),
        dtDraw: moment(this.item.dtDraw, 'DD/MM/YYYY HH:mm').format('YYYY-MM-DD HH:mm'),
        image: this.item.image,
        imageCupom: this.item.imageCupom,
        imageCupomVerso: this.item.imageCupomVerso,
        regulationSweepstakes: this.item.regulationSweepstakes ? this.item.regulationSweepstakes : '',
        config: this.item.config
      }

      if (this.item.id) {
        dados.id = this.item.id
        if (
            this.cloneSweepstakes.config.cardConfig &&
            this.cloneSweepstakes.config.cardConfig.expectedSales && 
            dados.config.cardConfig && 
            dados.config.cardConfig.expectedSales
        ) {
          if (this.cloneSweepstakes.config.cardConfig.expectedSales !== dados.config.cardConfig.expectedSales) {
            this.$swal({
              icon: 'warning',
              text: `O valor da previsão de vendas foi alterado, deseja atualizar os valores dos prêmios?`,
              showCancelButton: true,
              confirmButtonText: 'Sim',
              cancelButtonText: 'Não',
            })
            .then((result) => {
              if (result.value) {
                this.obterPremios({ sweepstakeId: dados.id })
                  .then(result => {
                    const prizePrevisto = dados.config.cardConfig.expectedSales * dados.config.cardConfig.payoutPercentage / 100
  
                    const arrPrizes = Object.values(result.data)
                    arrPrizes.pop()
                    arrPrizes.forEach(item => {
                      item.value = prizePrevisto * parseFloat(item.percentage) / 100
                      item.dtDraw = moment(item.dtDraw, 'DD/MM/YYYY HH:mm').format('YYYY-MM-DD HH:mm')
                      delete item.date
                    })
  
  
                    dados.prizes = arrPrizes
  
                    this.editarSweepstake(dados)
                  })
              } else {
                this.editarSweepstake(dados)
              }
            })
          
            return 
          }
        }

        this.editarSweepstake(dados)
      } else {
        // let arrayPrizes = [ Object.assign({}, ...this.listaItens)]
        let arrayPrizes = this.repetirPremios(dados, cloneDeep(this.listaItens))

        arrayPrizes.forEach(item => {
          item.dtDraw = moment(item.dtDraw, 'DD/MM/YYYY HH:mm').format('YYYY-MM-DD HH:mm')
          if (dados.ascertainedMethodId === 'BINGO') item.dtDraw === dados.dtDraw
          if (item.dtDraw === 'Invalid date') item.dtDraw === dados.dtDraw
        })

        dados.prizes = arrayPrizes
        
        this.cadastrarSweepstake(dados)
      }
    },

    repetirPremios (concurso, listPremios) {
      const premios = []
      let idxPremio = 1

      const premiosOrdenados = orderBy(listPremios, ['number'])

      premiosOrdenados.forEach(item => {
        if (item.prizeTypeId === 'PRC')  {
          item.percentage = item.percentage / concurso.config.cardConfig.numCols
          item.value = item.value / concurso.config.cardConfig.numCols

          for (let i = 0 ; i < concurso.config.cardConfig.numCols; i++) {
            this.addPremios(premios, item, idxPremio)
            idxPremio++
          }

          return
        }

        if (item.prizeTypeId === 'PRT')  {
          item.percentage = item.percentage / 2
          item.value = item.value / 2
          for (let i = 0 ; i < 2 ; i++) {
            this.addPremios(premios, item, idxPremio)
            idxPremio++
          }

          return
        }

        if (item.prizeTypeId === 'PRL')  {
          item.percentage = item.percentage / concurso.config.cardConfig.numLines
          item.value = item.value / concurso.config.cardConfig.numLines
          for (let i = 0 ; i < concurso.config.cardConfig.numLines; i++) {
            this.addPremios(premios, item, idxPremio)
            idxPremio++
          }

          return
        }

        this.addPremios(premios, item, idxPremio)
        idxPremio++
      })

      return premios
    },

    addPremios(premios, premio, idxPremio) {
      const clonePremio = { ...premio }
      clonePremio.number = idxPremio
      premios.push(clonePremio)
    },

    cadastrarSweepstake(dados) {
      this.cadastrarItem(dados).then(result => {
        this.getItens({ page: 1, pageSize: this.$store.getters.ItensPaginacao })
        this.tab = 'tab-2'
        this.item.id = result.data.id
        this.loading = false
        this.loadingCadastro = false
        this.closeModal()
        successSnackbar('Cadastro realizado com sucesso!')
      }).catch(err => {
        this.loading = false
        this.loadingCadastro = false
        errorSnackbar(err.error)
      })
    },

    editarSweepstake(dados) {
      this.editarItem(dados).then(() => {
        this.getItens({ page: 1, pageSize: this.$store.getters.ItensPaginacao })
        this.closeModal()
        this.loading = false
        this.loadingCadastro = false
        successSnackbar('Edição realizado com sucesso!')
      }).catch(err => {
        this.loading = false
        this.loadingCadastro = false
        errorSnackbar(err.error)
      })
    }
  },

  validations () {
    return  {
      item: {
        ascertainedMethodId: {required },
        cod: {required },
        config: {
          price: { required, minValue: minValue(0.01) },
          sizeLot: { required: requiredIf(() => this.visibleQtdCupons),  minValue: minValue(1) },
          // timer: { required: requiredIf(() => this.isBingo) },
          cardConfig: {
            maxNumber: { 
              required: requiredIf(() => this.isBingo),
              minValue:  minValue(90)
            },
            expectedSales: {
              required: requiredIf(() => this.isBingo),
              minValue:  minValue(0.01)
            },
            payoutPercentage: { 
              required: requiredIf(() => this.isBingo),
              minValue:  minValue(0.01)
            },
          },
          accumulation: {
            // maxBalls: { required: requiredIf(() => this.isBingo) },
            // percentage: { required: requiredIf(() => this.isBingo) }
          }
        },
        dtStart: {required },
        dtEnd: {
          required,
          invalidDate: paramsInvalidDateEnd(this.item.dtStart)
        },
        dtDraw: {
          required,
          invalidDate: paramsInvalidDateDraw(this.item.dtEnd)
        },
      }
    }
  }
}
</script>

<style lang="scss" src="@/assets/styles/view/modal-cadastro.scss"></style>